import { client as mpcClient } from './../../shared/http/mpc-client'

export const getCompanyDealers = companyId => {
  return mpcClient
    .post('/graphql', {
      query: `
        query searchDealers($input: SearchDealersInput!) {
          searchDealers(input: $input) {
            docs {
              _id
              name
              phones {
                  fixed {
                      countryCallingCode
                      areaCode
                      number
                  }
              }
              coordinates {
                  lat
                  lon
              }
              address {
                  street
                  houseNumber
                  city
                  countryCode
                  zipcode
              },
              source {
                key
              }
            }
          }
        }
      `,
      variables: {
        input: {
          _company: companyId,
          query: {
            filters: []
          },
          options: {
            limit: 1000
          }
        }
      }
    })
    .then(res => {
      return res.data.data.searchDealers.docs
    })
}

export const getVehicleMarketDealersByCompany = companyId => {
  return mpcClient
    .post('/graphql', {
      query: `
        query getVehicleMarketDealersByCompany($input: ID!) {
            getVehicleMarketDealersByCompany(_company: $input) {
              _id
              extended
              vehicleMarket
            }
        }
      `,
      variables: {
        input: companyId
      }
    })
    .then(res => {
      return res.data.data.getVehicleMarketDealersByCompany
    })
}

export default {
  getCompanyDealers,
  getVehicleMarketDealersByCompany
}
