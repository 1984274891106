import Vue from 'vue'
import Vuex from 'vuex'
import { createCoreModule } from './modules/core'
import { createVehicleDetailPageModule } from './modules/vehicle-detail-page'
import { createFavoritesModule } from './modules/favorites'
import { createSearchHybridModule } from './modules/search-hybrid'
import { createConsentModule } from './modules/consent'

Vue.use(Vuex)

export function createStore() {
  const storeObj = {
    modules: {
      core: createCoreModule(),
      vehicleDetailPage: createVehicleDetailPageModule(),
      favorites: createFavoritesModule(),
      searchHybrid: createSearchHybridModule(),
      consent: createConsentModule()
    }
  }

  return new Vuex.Store(storeObj)
}
