import { client as mpcClient } from './../../shared/http/mpc-client'

export const searchVehiclesByCompany = (
  companyId,
  params = { flags: [] },
  options = {}
) => {
  return mpcClient
    .post('/graphql', {
      query: `
        query SearchVehiclesByCompany($_company: ID!, $params: VehicleSearchParameters!, $options: PaginationInput! ) {
          searchVehiclesByCompany(
            input: {
              _company: $_company
              params: $params
              options:  $options
            }
          ) {
            docs {
              _id
              json
            }
            total
            limit
            offset
            page
            pages
          }
        }
      `,
      variables: {
        _company: companyId,
        params,
        options
      }
    })
    .then(res => {
      return Object.assign({}, res.data.data.searchVehiclesByCompany, {
        docs: res.data.data.searchVehiclesByCompany.docs.map(d =>
          JSON.parse(d.json)
        )
      })
    })
}

export const facetVehiclesByCompany = (companyId, params = { flags: [] }) => {
  return mpcClient
    .post('/graphql', {
      query: `
        query FacetVehiclesByCompany(
          $_company: ID!
          $params: VehicleSearchParameters!
        ) {
          facetVehiclesByCompany(input: { _company: $_company, params: $params }) {
            total {
              count
            }
            categorizedByMake {
              _id
              count
            }
            categorizedBySeries {
              _id
              count
            }
            categorizedByModel {
              _id
              count
            }
            categorizedByVehicleType {
              _id
              count
            }
            categorizedByUsageType {
              _id
              count
            }
            categorizedByBodyType {
              _id
              count
            }
            categorizedByFuel {
              _id
              count
            }
            categorizedByUsedCarSeal {
              _id
              count
            }
            categorizedByAmUsageType {
              _id
              count
            }
          }
        }
      `,
      variables: {
        _company: companyId,
        params
      }
    })
    .then(res => {
      return res.data.data.facetVehiclesByCompany
    })
}

export default {
  searchVehiclesByCompany,
  facetVehiclesByCompany
}
