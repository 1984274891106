export default () => ({
  locale: 'de-DE',
  origin: null,
  canonicalBaseUrl: null,
  appMode: 'spa',
  theme: {
    name: 'automedia',
    primaryColor: null
  },
  mpcApi: {
    token: null,
    companyId: null,
    baseUrl: null
  },
  company: {
    id: null,
    name: null
  },
  rawVehicleMarketCompanyData: {
    isAdMode: false,
    vehicleDefaultImages: [
      {
        source: 'https://pictures.automedia.de/Placeholder_Car_Wall.jpg',
        condition: {
          vehicleClass: 'Car'
        }
      },
      {
        source: 'https://pictures.automedia.de/Placeholder_Motorcycle_Wall.jpg',
        condition: {
          vehicleClass: 'Motorbike'
        }
      }
    ]
  },
  rawCompanyDealerData: [],
  notifications: [],
  notificationIdIncrement: 1,
  hasFetchedAppData: false,
  googleMaps: {
    loaded: false, // will be set to true when script loaded in app
    apiKey: 'AIzaSyA2HwZBO-Fev6iowlQRwXGBmqHZz2EMDfY'
  },
  validConsentServices: {
    // keys are our internal names, values the names of usercentrics
    autouncle: 'AutoUncle Aps',
    ga4: 'Google Analytics 4',
    googleMaps: 'Google Maps',
    gtm: 'Google Tag Manager',
    userlike: 'Userlike'
  },
  settings: {
    vehicleClass: {
      configured: [], // [] = all available
      available: [] // all available classes based on the configured classes, if configured is empty array, then all available by data without limitation. the value will be set by app
    },
    isCommercial: false, // should be always false as long as we have not commercial mode
    leasing: {
      minRate: 1500 // in cent
    },
    financing: {
      minRate: 1500 // in cent
    },
    priceTrend: {
      maxDaysSinceLastPriceChange: 3,
      minPriceDifferenceInPercent: {
        default: 10,
        g: 1
      }
    },
    router: {
      routes: {
        app: '/',
        detail: '/angebot/:id',
        favorites: '/favoriten',
        tradeIn: '/fahrzeugankauf'
      }
    }
  }
})
