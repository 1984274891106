export default {
  getIntegrationByName: (state, getters) => (name, mobileSellerId = null) => {
    let integrations = []

    if (mobileSellerId) {
      // by dealer
      const dealerData = getters.getDealerByMobileSellerId(mobileSellerId)

      if (dealerData) {
        integrations = dealerData?.vehicleMarket?.integrations
        integrations = Array.isArray(integrations) ? integrations : []
      }
    } else {
      // by company
      integrations = state.rawVehicleMarketCompanyData?.integrations
      integrations = Array.isArray(integrations) ? integrations : []
    }

    return integrations.find(d => d.name === name)
  },
  routes: state => {
    return state.settings.router.routes
  },
  companyId: state => {
    return state.company.id || state.mpcApi.companyId
  },
  companyName: state => {
    return state.company.name
  },
  getDealerByMobileSellerId: state => mobileSellerId => {
    const dealer = state.rawCompanyDealerData.find(
      d => d.source.key === mobileSellerId
    )
    return dealer || null
  },
  notification: state => {
    return state.notifications[0] !== undefined ? state.notifications[0] : null
  },
  mpcApi: state => {
    return state.mpcApi
  },
  trackingConfigurations: () => {
    // TODO TRACKING: outsource into config
    const gtm = [
      {
        appMode: ['ssr'], // 'spa', 'widget'
        config: {
          property: 'GTM-NDH67PX'
        },
        name: 'gtm'
      }
    ]
    // return (state.rawVehicleMarketCompanyData?.tracking || []).concat(gtm)
    return gtm
  },
  cmp: state => {
    return state.rawVehicleMarketCompanyData?.cmp || null
  },
  canonicalBaseUrl: state => {
    return state.canonicalBaseUrl
  },
  origin: state => {
    return state.origin
  },
  isAdMode: state => {
    return state.rawVehicleMarketCompanyData.isAdMode || false
  },
  isNoIndexMode: state => {
    return state.rawVehicleMarketCompanyData.isNoIndexMode || false
  }
}
