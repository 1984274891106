<template>
  <div v-if="isLoading">is loading...</div>
  <div
    v-else
    id="app"
    :class="{
      'am-body': true,
      'am-body--layout': this.appMode !== 'widget'
    }"
    :style="cssVars"
  >
    <am-header
      v-if="appMode !== 'widget'"
      :title="appTitle"
      :logo-source="logoSource"
      :brand-sources="brandSources"
      :navigation="primaryNavigation"
      :home-link="homeLink"
      :is-ad-mode="isAdMode"
      :favorite-vehicles-count="favoriteVehiclesCount"
      @change-route="onChangeRoute"
    />
    <main>
      <router-view />
    </main>
    <am-footer
      v-if="appMode !== 'widget'"
      :social-media-links="socialMediaLinks"
      :meta-navigation="metaNavigation"
    />
    <am-userlike-chat-button
      v-if="
        appMode !== 'widget' &&
          userlikeChatWidget &&
          hasAcceptedCookiesForUserlike === false
      "
      :aria-label="$t('userlike.messenger.buttonLabel')"
      @click.native="openDataPrivacyUserlikeDialog"
    />
    <amui-modal
      v-model="hasOpenDataPrivacyUserlikeDialog"
      @close="closeDataPrivacyUserlikeDialog"
    >
      <am-userlike-privacy-dialog
        @confirm="allowUserlikeService"
        @cookie-settings="openCookieSettings"
        @close="closeDataPrivacyUserlikeDialog"
      />
    </amui-modal>

    <am-notifications />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import AmHeader from '@/app/components/header/header'
import AmFooter from '@/app/components/footer/footer'
import AmNotifications from '@/app/components/notifications/notifications'
import AmUserlikeChatButton from '@/app/components/userlike-chat-button/userlike-chat-button'
import AmUserlikePrivacyDialog from '@/app/components/userlike-privacy-dialog/userlike-privacy-dialog'
import { AmuiModal } from '@/../ui/components/modal'
import { createMessenger as createUserlikeMessenger } from '@userlike/messenger'
import { getGTMTracker } from '@/app/tracking/gtm.js'

export default {
  name: 'App',

  components: {
    AmHeader,
    AmFooter,
    AmNotifications,
    AmUserlikeChatButton,
    AmUserlikePrivacyDialog,
    AmuiModal
  },

  metaInfo() {
    let ssrMetaInfo = {}
    let script = []
    let link = []
    let meta = []

    let metaInfo = {
      title: 'Vehicle market',
      titleTemplate: '%s | powered by Automedia'
    }

    this.trackingSources.forEach(s => {
      script.push({
        src: s.source,
        async: true
      })
    })

    if (this.canonicalUrl) {
      link.push({
        rel: 'canonical',
        href: this.canonicalUrl,
        vmid: 'canonical'
      })
    }

    if (this.isNoIndexMode) {
      meta.push({
        name: 'robots',
        content: 'noindex,nofollow'
      })
    }

    if (this.appMode !== 'widget') {
      meta.push({ charset: 'utf-8' })
      meta.push({
        'http-equiv': 'X-UA-Compatible',
        content: 'IE=edge'
      })
      meta.push({
        name: 'viewport',
        content: 'width=device-width, initial-scale=1'
      })

      if (this.googleSiteVerificationId) {
        meta.push({
          name: 'google-site-verification',
          content: this.googleSiteVerificationId
        })
      }

      ssrMetaInfo = {
        htmlAttrs: {
          lang: 'de'
        },
        link: [],
        style: [
          {
            cssText: 'html { height: 100%; } body { height: 100%; margin: 0; }',
            type: 'text/css'
          }
        ],
        noscript: [
          {
            innerHTML:
              "This Application doesn't work properly without JavaScript enabled. Please enable it to continue."
          }
        ],
        script: []
      }

      link.push({
        rel: 'favicon',
        href: 'favicon.png'
      })

      if (this.hasFetchedAppData) {
        if (!this.cmp?.settingsId) {
          console.error('CMP: customer specific settings id is missing!')
        }

        script.push({
          src: 'https://app.usercentrics.eu/browser-ui/latest/loader.js',
          async: true,
          'data-settings-id': this.cmp?.settingsId || 'bkorwzK6', // TODO move default settings id to a config
          'data-tcf-enabled': true,
          id: 'usercentrics-cmp'
        })

        if (this.userlikeChatWidget) {
          link.push({
            rel: 'preconnect',
            href: 'https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com'
          })
        }
      }
    }

    metaInfo = Object.assign({}, metaInfo, ssrMetaInfo, { meta, script, link })

    return metaInfo
  },

  data() {
    return {
      trackingSources: [],
      initiallyOpenUserlikeChat: false,
      hasOpenDataPrivacyUserlikeDialog: false,
      hasLoadedGoogleMaps: false,
      hasLoadedGTM: false,
      consent: null,
      gtm: null
    }
  },

  computed: {
    ...mapState('core', {
      locale: 'locale',
      appMode: 'appMode',
      rawVehicleMarketCompanyData: 'rawVehicleMarketCompanyData',
      hasFetchedAppData: 'hasFetchedAppData',
      googleMaps: 'googleMaps',
      validConsentServices: 'validConsentServices'
    }),
    ...mapGetters('consent', { consentAllowedServices: 'allowedServices' }),
    ...mapGetters('searchHybrid', ['getInitialSearchParams']),
    ...mapGetters('core', {
      companyId: 'companyId',
      getIntegrationByName: 'getIntegrationByName',
      trackingConfigurations: 'trackingConfigurations',
      cmp: 'cmp',
      canonicalBaseUrl: 'canonicalBaseUrl',
      origin: 'origin',
      _isAdMode: 'isAdMode',
      isNoIndexMode: 'isNoIndexMode'
    }),
    ...mapState('favorites', {
      favoriteVehiclesCount: state => state.vehicles.length
    }),
    isAdMode() {
      return this._isAdMode || this.$route.query.ad ? true : false
    },
    canonicalUrl() {
      let segment = this.$router.resolve(this.$route).resolved.fullPath

      return this.canonicalBaseUrl + segment
    },
    hasAcceptedCookiesForUserlike() {
      let retVal = null

      if (this.consent !== null) {
        retVal = this.consent['Userlike']
      }

      return retVal
    },
    autouncleTradeInIntegration() {
      return this.getIntegrationByName('autouncle-trade-in')
    },
    userlikeChatWidget() {
      let widget = null

      const integration = this.getIntegrationByName('userlike')

      if (integration !== undefined) {
        const foundWidget = integration.settings.widgets.find(
          setting => setting.lang === 'de-DE'
        )

        if (foundWidget !== undefined) {
          widget = foundWidget
        }
      }

      return widget
    },
    isLoading() {
      return !this.hasFetchedAppData
    },
    cssVars() {
      return {
        '--amui-color-brand': this.$store.state.core.theme.primaryColor
      }
    },
    // getters for header component
    // TODO: move that to a better place where it will not belong to the bundle if appMode is widget
    appTitle() {
      let title

      if (this.rawVehicleMarketCompanyData !== null) {
        title = this.rawVehicleMarketCompanyData.appTitle.find(
          d => d.lang === this.locale
        )
      }

      return title?.text || null
    },
    logoSource() {
      return (
        this.rawVehicleMarketCompanyData?.media?.logo?.image?.source || null
      )
    },
    brandSources() {
      let sources = []

      if (this.rawVehicleMarketCompanyData !== null) {
        sources = this.rawVehicleMarketCompanyData.brands.map(brand => {
          return {
            src:
              'https://cdn.automedia.de/widgets/vehicle-market/assets/brands/' +
              brand +
              '.svg',
            alt: brand + ' Logo'
          }
        })
      }

      return sources
    },
    homeLink() {
      let link

      if (this.rawVehicleMarketCompanyData !== null) {
        link = this.rawVehicleMarketCompanyData.homeLink.find(
          d => d.lang === this.locale
        )
      }

      return link || null
    },
    imprintUrl() {
      let url = null

      if (this.rawVehicleMarketCompanyData?.imprintUrl !== undefined) {
        const found = this.rawVehicleMarketCompanyData.imprintUrl.find(
          d => d.lang === this.locale
        )

        if (found !== undefined) {
          url = found.href
        }
      }

      return url
    },
    privacyPolicyUrl() {
      let url = null

      if (this.rawVehicleMarketCompanyData?.privacyPolicyUrl !== undefined) {
        const found = this.rawVehicleMarketCompanyData.privacyPolicyUrl.find(
          d => d.lang === this.locale
        )

        if (found !== undefined) {
          url = found.href
        }
      }

      return url
    },
    primaryNavigation() {
      let navigation = []

      if (!this.isAdMode) {
        navigation.push({
          text: 'Unsere Fahrzeuge',
          href: this.$router.resolve({ name: 'app' }).href,
          active: ['detail', 'app'].includes(this.$route.name),
          target: null,
          badge: null,
          icon: null,
          route: { name: 'app' }
        })

        // "Aktionen" will be skipped for the moment

        if (this.autouncleTradeInIntegration) {
          navigation.push({
            text: 'Fahrzeugankauf',
            href: this.$router.resolve({ name: 'trade-in' }).href,
            active: this.$route.name === 'trade-in',
            target: null,
            badge: null,
            icon: null,
            route: { name: 'trade-in' }
          })
        }

        if (this.favoriteVehiclesCount > 0) {
          navigation.push({
            text: 'Favoriten',
            href: this.$router.resolve({ name: 'favorites' }).href,
            active: this.$route.name === 'favorites',
            target: null,
            badge: this.favoriteVehiclesCount,
            icon: null,
            route: { name: 'favorites' }
          })
        }
      }

      return navigation
    },
    // Footer data bindings

    metaNavigation() {
      let links

      if (this.rawVehicleMarketCompanyData !== null) {
        links = this.rawVehicleMarketCompanyData.metaNavigation
          .filter(d => d.lang === this.locale)
          .map(d => ({
            text: d.text,
            href: d.href
          }))
      }

      return links
    },

    socialMediaLinks() {
      let links

      if (this.rawVehicleMarketCompanyData !== null) {
        links = this.rawVehicleMarketCompanyData.socialMedia
          .filter(d => d.lang === this.locale)
          .map(d => ({
            name: d.name,
            text: d.text,
            href: d.href
          }))
      }

      return links
    },
    googleSiteVerificationId() {
      let id = null

      if (
        this.rawVehicleMarketCompanyData?.googleSiteVerification !== undefined
      ) {
        id = this.rawVehicleMarketCompanyData?.googleSiteVerification
      }

      return id
    }
  },

  watch: {
    consentAllowedServices(services) {
      const hasGoogleMapsConsent = services.includes('googleMaps')

      if (hasGoogleMapsConsent && this.googleMaps.loaded === false) {
        this.loadGoogleMaps()
      }

      this.handleGTMLoad()
    }
  },

  created() {
    // set canconial base url if provided by company data, the canonical is only for ssr/spa
    // for widget pass a config param locally
    if (this.appMode !== 'widget') {
      let canonicalBaseUrl = this.rawVehicleMarketCompanyData?.canonicalBaseUrl

      if (!canonicalBaseUrl) {
        canonicalBaseUrl = this.origin
      }

      this.setCanonicalBaseUrl(canonicalBaseUrl)
    }
  },

  mounted() {
    this.gtm = getGTMTracker()
    this.handleGTMLoad()

    if (this.appMode !== 'widget') {
      window.addEventListener('am-uc-event', this.handleUCEvent)
      window.addEventListener('onUcPrivacyClick', this.redirectToPrivacyUrl)
      window.addEventListener('onUcImprintClick', this.redirectToImprintUrl)

      if (window.UC_UI) {
        const allowedServices = []
        const services = window.UC_UI.getServicesBaseInfo()

        const validUsercentricsServices = Object.values(
          this.validConsentServices
        )

        validUsercentricsServices.forEach(serviceName => {
          const foundService = services.find(s => s.name === serviceName)

          if (foundService !== undefined && foundService.consent.status) {
            allowedServices.push(foundService.name)
          }
        })

        this.allowService(allowedServices)
      }
    }
  },

  beforeDestroy() {
    if (this.appMode !== 'widget') {
      window.removeEventListener('am-uc-event', this.handleUCEvent)
      window.removeEventListener('onUcPrivacyClick', this.redirectToPrivacyUrl)
      window.removeEventListener('onUcImprintClick', this.redirectToImprintUrl)
    }
  },

  methods: {
    ...mapActions('core', {
      _fetchCompanyById: 'fetchCompanyById',
      _fetchRawCompanyDealerData: 'fetchRawCompanyDealerData',
      _fetchAvailableVehicleClasses: 'fetchAvailableVehicleClasses',
      _fetchRawVehicleMarketCompanyData: 'fetchRawVehicleMarketCompanyData',
      _fetchRawVehicleMarketDealersData: 'fetchRawVehicleMarketDealersData',
      _addIntegration: 'addIntegration',
      setHasFetchedAppData: 'setHasFetchedAppData',
      setCanonicalBaseUrl: 'setCanonicalBaseUrl',
      setGoogleMapsLoadingState: 'setGoogleMapsLoadingState',
      loadGoogleMaps: 'loadGoogleMaps'
    }),
    ...mapActions('consent', {
      allowService: 'allowService',
      disallowService: 'disallowService'
    }),

    handleGTMLoad() {
      if (this.gtm !== undefined) {
        const gtmConfig = this.trackingConfigurations.find(
          c => c.name === 'gtm'
        )

        if (
          this.consentAllowedServices.includes('gtm') &&
          gtmConfig !== undefined &&
          gtmConfig.appMode.includes(this.appMode) &&
          !this.hasLoadedGTM
        ) {
          this.gtm.enable()
          this.gtm.setProperty(gtmConfig.config.property)

          this.trackingSources.push({
            id: this.gtm.getScriptIdentifier(),
            source: this.gtm.getScriptUrl()
          })
        }
      }
    },

    async createUserlikeApi() {
      const result = await createUserlikeMessenger({
        version: 1,
        widgetKey: this.userlikeChatWidget.id
      })

      const { api } = result.value
      return api
    },

    handleUCEvent(e) {
      if (e.detail && e.detail.event === 'consent_status') {
        if (e.detail['Google Tag Manager'] === true) {
          this.allowService(['gtm'])
        } else {
          this.disallowService(['gtm'])
        }

        if (e.detail['Google Maps'] === true) {
          this.allowService(['googleMaps'])
        } else {
          this.disallowService(['googleMaps'])
        }

        if (e.detail['AutoUncle Aps'] === true) {
          this.allowService(['autouncle'])
        } else {
          this.disallowService(['autouncle'])
        }

        if (this.userlikeChatWidget) {
          if (e.detail['Userlike'] === true) {
            this.loadUserlikeChat()
          }
        }

        const validUsercentricsServices = Object.values(
          this.validConsentServices
        )

        // reload page if service is no longer active, because of SPA
        if (this.consent !== null) {
          const hasToReload = validUsercentricsServices.some(
            service =>
              this.consent[service] === true &&
              e.detail &&
              e.detail[service] === false
          )
          if (hasToReload) {
            window.location.reload()
          }
        }

        // save consent locally
        const consent = {}
        validUsercentricsServices.forEach(service => {
          consent[service] = e.detail[service]
        })
        this.consent = consent
      }
    },

    redirectToPrivacyUrl() {
      if (this.privacyPolicyUrl) {
        window.location.href = this.privacyPolicyUrl
      }
    },

    redirectToImprintUrl() {
      if (this.imprintUrl) {
        window.location.href = this.imprintUrl
      }
    },

    loadUserlikeChat() {
      window.userlike = this.createUserlikeApi()
      window.userlike.then(messenger => {
        messenger.logout().then(() =>
          messenger.mount().then(() => {
            messenger
              .setVisibility({
                main: true,
                button: true,
                notifications: true
              })
              .then(() => {
                if (this.initiallyOpenUserlikeChat) {
                  setTimeout(() => {
                    messenger.maximize()
                    this.initiallyOpenUserlikeChat = false
                  }, 400)
                }
              })
          })
        )
      })
    },

    openDataPrivacyUserlikeDialog() {
      this.hasOpenDataPrivacyUserlikeDialog = true
    },

    closeDataPrivacyUserlikeDialog() {
      this.hasOpenDataPrivacyUserlikeDialog = false
    },

    allowUserlikeService() {
      if (this.appMode === 'widget') {
        window.dispatchEvent(
          new CustomEvent('am-consent-accept-service', {
            detail: {
              service: 'userlike'
            }
          })
        )
      } else {
        if (window.UC_UI) {
          this.initiallyOpenUserlikeChat = true
          const templateId = window.UC_UI.getServicesBaseInfo().find(
            service => service.name === 'Userlike'
          ).id

          window.UC_UI.acceptService(templateId)
          this.closeDataPrivacyUserlikeDialog()
        }
      }
    },

    openCookieSettings() {
      this.closeDataPrivacyUserlikeDialog()
      if (this.appMode === 'widget') {
        window.dispatchEvent(
          new CustomEvent('am-consent-show-settings', {
            detail: {
              service: 'userlike'
            }
          })
        )
      } else {
        window.UC_UI.showSecondLayer()
      }
    },

    onChangeRoute(route) {
      this.$router.push(route)
    }
  }
}
</script>
