export const SET_APP_MODE = 'SET_APP_MODE'
export const SET_ORIGIN = 'SET_ORIGIN'
export const SET_THEME = 'SET_THEME'
export const SET_COMPANY_ID = 'SET_COMPANY_ID'
export const SET_COMPANY_NAME = 'SET_COMPANY_NAME'
export const SET_RAW_COMPANY_DEALER_DATA = 'SET_RAW_COMPANY_DEALER_DATA'
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_CONFIGURED_VEHICLE_CLASS = 'SET_CONFIGURED_VEHICLE_CLASS'
export const SET_AVAILABLE_VEHICLE_CLASS = 'SET_AVAILABLE_VEHICLE_CLASS'
export const ADD_INTEGRATION = 'ADD_INTEGRATION'
export const SET_RAW_VEHICLE_MARKET_COMPANY_DATA =
  'SET_RAW_VEHICLE_MARKET_COMPANY_DATA'
export const SET_MPC_API = 'SET_MPC_API'
export const SET_HAS_FETCHED_APP_DATA = 'SET_HAS_FETCHED_APP_DATA'
export const SET_CANONICAL_BASE_URL = 'SET_CANONICAL_BASE_URL'
export const SET_GOOGLE_MAPS_LOADING_STATE = 'SET_GOOGLE_MAPS_LOADING_STATE'
