import {
  ADD_VEHICLE,
  REMOVE_VEHICLE_BY_ID,
  SET_RAW_VEHICLE_DATA
} from './mutation-types'
import {
  getId as getVehicleId,
  getTitle as getVehicleTitle
} from './../../../../../utils/vehicle'
import { getVehicleById } from '@/app/requests/vehicle'

// TODO: optimize the fetch, one request insteasd of separate ones? async instead of sequence
// Promise.all?
export const fetchVehicles = ({ commit, state, rootGetters }) => {
  const rawVehicleData = []
  const promises = []
  state.vehicles.forEach(vehicle => {
    promises.push(getVehicleById(rootGetters['core/companyId'], vehicle.id))
  })

  return Promise.allSettled(promises).then(results => {
    results.forEach(result => {
      if (result.status === 'fulfilled' && result.value !== null) {
        rawVehicleData.push(result.value)
      }
    })
    commit(SET_RAW_VEHICLE_DATA, rawVehicleData)
  })
}

export const toggleFavoriteStateByRawVehicleData = (
  { commit, getters },
  rawVehicleData
) => {
  const vehicleId = getVehicleId(rawVehicleData)
  const isFavorite = getters.isFavoriteByVehicleId(vehicleId)

  if (isFavorite) {
    removeVehicleById({ commit }, vehicleId)
  } else {
    addVehicle({ commit }, rawVehicleData)
  }

  return Promise.resolve(!isFavorite)
}

export const addVehicle = ({ commit }, rawVehicleData) => {
  const vehicleId = getVehicleId(rawVehicleData)
  commit(ADD_VEHICLE, {
    id: vehicleId,
    title: getVehicleTitle(rawVehicleData),
    data: {
      make: rawVehicleData.make,
      model: rawVehicleData.model,
      power: rawVehicleData.power,
      firstRegistration: rawVehicleData.firstRegistration,
      mileage: rawVehicleData.mileage,
      gearbox: rawVehicleData.gearbox,
      fuel: rawVehicleData.fuel,
      consumptionCombined: rawVehicleData.consumptionCombined,
      co2: rawVehicleData.co2,
      wltpValues: rawVehicleData.wltpValues,
      mobileSellerId: rawVehicleData.mobileSellerId,
      mobileAdId: rawVehicleData.mobileAdId,
      internalNumber: rawVehicleData.internalNumber,
      usageType: rawVehicleData.usageType,
      condition: rawVehicleData.condition,
      consumptionUnit: rawVehicleData.consumptionUnit,
      energyEfficiencyClass: rawVehicleData.energyEfficiencyClass,
      emissionClass: rawVehicleData.emissionClass,
      vehicleClass: rawVehicleData.vehicleClass,
      amModelYear: rawVehicleData.amModelYear,
      manufacturerColorName: rawVehicleData.manufacturerColorName,
      exteriorColor: rawVehicleData.exteriorColor,
      interiorColor: rawVehicleData.interiorColor,
      interiorType: rawVehicleData.interiorType,
      highlights: rawVehicleData.highlights
    }
  })
}

export const removeVehicleById = ({ commit }, id) => {
  commit(REMOVE_VEHICLE_BY_ID, id)
}

export default {
  fetchVehicles,
  toggleFavoriteStateByRawVehicleData,
  addVehicle,
  removeVehicleById
}
